/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const { pathname } = location;
  const oldPathname = prevLocation ? prevLocation.pathname : null;
  if (oldPathname) {
    document.documentElement.setAttribute('data-old-pathname', oldPathname);
  } else {
    document.documentElement.removeAttribute('data-old-pathname');
  }
  if (pathname) {
    document.documentElement.setAttribute('data-pathname', pathname);
  } else {
    document.documentElement.removeAttribute('data-pathname');
  }
};
