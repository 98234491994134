// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-batteries-js": () => import("./../../../src/pages/batteries.js" /* webpackChunkName: "component---src-pages-batteries-js" */),
  "component---src-pages-battery-js": () => import("./../../../src/pages/battery.js" /* webpackChunkName: "component---src-pages-battery-js" */),
  "component---src-pages-cbd-js": () => import("./../../../src/pages/cbd.js" /* webpackChunkName: "component---src-pages-cbd-js" */),
  "component---src-pages-cliq-js": () => import("./../../../src/pages/cliq.js" /* webpackChunkName: "component---src-pages-cliq-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-find-us-js": () => import("./../../../src/pages/find-us.js" /* webpackChunkName: "component---src-pages-find-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mix-it-recipes-js": () => import("./../../../src/pages/mix-it-recipes.js" /* webpackChunkName: "component---src-pages-mix-it-recipes-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-select-squeeze-js": () => import("./../../../src/pages/select-squeeze.js" /* webpackChunkName: "component---src-pages-select-squeeze-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/signUp.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-style-guide-js": () => import("./../../../src/pages/style-guide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-pages-women-js": () => import("./../../../src/pages/women.js" /* webpackChunkName: "component---src-pages-women-js" */)
}

